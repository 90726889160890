import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Select, Button, Row, Col, Empty, Divider, Statistic,
  Card, Drawer,
} from "antd";
import { useAuth } from "../../assets/utils/useAuth";
import "./index.css";
import { myRequest } from "../../assets/utils/lib";
import CountUp from "react-countup";
import Organizations from "../orgs";
import CheckinListHook, { CheckinListAnalytics, CheckinListAnalyticsInPatioent } from "../checkins";
import { ClaimsListHook, ClaimsListHookPayment } from "../claims";
import { gender, states } from "../../assets/utils/config";
import { useNavigate, useLocation } from "react-router-dom";
import Logging from "../logging";
import Loader from "../compoinents/loader";
import ConfigurableRangePicker from "../compoinents/ConfigurableRangePicker";
import DebounceSelect from "../compoinents/DebouncedSelect";

export const formatter = (value) => <CountUp end={value} separator="," />;

const PlansSummary = (props) => {
  const { user } = useAuth();
  const navigator = useNavigate();
  const { pathname } = useLocation();
  const [summary, setSummary] = useState({});
  const [filter, setFilter] = useState({});
  const [filterDate, setFilterDate] = useState({ start: null, end: null });
  const [loading, setLoading] = useState(false);
  const [loadWithFilter, setLoadWithFilter] = useState(false);
  const [filterReste, setresetFilter] = useState(false);
  const [showOrg, setShowOorg] = useState(false);
  const [showClaimsPaid, setShowClaimsPaid] = useState(false);
  const [showClaimsPendingPayment, setShowClaimsPendingPayment] =
    useState(false);

  // Statistically Related States
  const [showAllClaims, setShowAllClaims] = useState(false);
  const [showAllClaimsCFO, setShowAllClaimsCFO] = useState(false);
  const [showDeclinedClaims, setShowDeclinedClaims] = useState(false);
  const [showPendingClaims, setShowPendingClaims] = useState(false);
  const [showPaidClaims, setShowPaidClaims] = useState(false);
  const [showCheckind, setShowCheckind] = useState(false);
  const [showLogs, setShowLogs] = useState(false);
  const [packages, setPackages] = useState([]);

  const [checkTypeToShow, setCheckTypeToShow] = useState(null);

  const timerId = useRef();
  const currentRequest = useRef(false);
  const pickerRef = useRef();
  const interval = 60000;

  const loadPlans = () => {
    myRequest('post', 'orgPlansPublic')
      .then(data => data?.data)
      .then(data => {
        if (data?.error) {
          notification.error({ message: data?.message })
        } else {
          const wrangled = data?.data?.map(plan => (
            {
              label: plan.plan_name,
              value: plan.plan_id
            }
          ))
          setPackages(wrangled)
        }
      })
  }

  const loadOrgs = async (search) => {
    console.log('loadOrgs', search)
    return myRequest('post', 'organizations', {mode: 'mini', search})
      .then(data => data?.data)
      .then(data => {
        return data? data?.data?.map(org => {
          return {label: org.name, value: org.social_no, key: org.id}
        }) : []
      })
  }

  useEffect(() => {
    if (pathname !== "/dashboard") clearInterval(timerId);
  }, [pathname]);

  useEffect(() => {
    if (loadWithFilter && Object.keys(filter)?.length) {
      clearInterval(timerId);
      getSummary(true, filter);
    }

    return () => clearInterval(timerId.current);
  }, [loadWithFilter, filter]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterReste) {
      clearInterval(timerId);
      getSummary(true);
      timerId.current = setInterval(() => {
        getSummary(false);
      }, interval);
    }
    setresetFilter(false);
    return () => clearInterval(timerId.current);
  }, [filterReste]); //eslint-disable-line react-hooks/exhaustive-deps

  const getSummary = (first = false, payload = {}) => {
    if (currentRequest.current && !first) return
    if (first) setLoading(true);
    let filtered = { ...payload };

    currentRequest.current = true
    myRequest("post", "plansSummary", filtered)
      .then((resp) => resp.data)
      .then((planTypes) => {
        if (planTypes && !planTypes?.error && !planTypes?.data?.length)
          setSummary(planTypes?.data);
      })
      .finally(() => {
        setLoading(false);
        currentRequest.current = false;
      });
  };

  useEffect(() => {
    if (user) {
      if (![1, 2, 3, 4, 5].includes(Number(user.role.staffRoleId))) {
        navigator("/login");
      } else {
        getSummary(true);
        timerId.current = setInterval(() => {
          getSummary(false);
        }, interval);
        loadPlans();
      }
    } else {
      clearInterval(timerId.current);
    }
    return () => clearInterval(timerId.current);
  }, [user]); //eslint-disable-line react-hooks/exhaustive-deps

  // Finanically Related
  const showOrgDrawer = () => setShowOorg(!!!showOrg);
  const showClaimsPaidDrawer = () => setShowClaimsPaid(!!!showClaimsPaid);
  const showAClaimPendingPaymentDrawer = () =>
    setShowClaimsPendingPayment(!!!showClaimsPendingPayment);

  // Statistically Related
  const showAllClaimDrawerCFO = () => setShowAllClaimsCFO(!!!showAllClaimsCFO);
  const showAllClaimDrawer = () => setShowAllClaims(!!!showAllClaims);
  const showDeclinedClaimDrawer = () => setShowDeclinedClaims(!!!showDeclinedClaims);
  const showPendingClaimDrawer = () => setShowPendingClaims(!!!showPendingClaims);
  const showPaidClaimDrawer = () => setShowPaidClaims(!!!showPaidClaims);
  const showCheckedClients = (type = null) => {
    setCheckTypeToShow(type);
    setShowCheckind(!!!showCheckind);
  }

  const showLogsDrawer = () => setShowLogs(!!!showLogs);

  const onChange = (date) => {
    setFilter((d) => ({ ...d, date: date }));
    setFilterDate({ start: date[0], end: date[1] });
  };

  const refreshSummary = () => {
    setLoadWithFilter(true);
    setresetFilter(false);
  };

  const resetFilter = () => {
    setFilter({ date: [null, null] });
    pickerRef.current.clearDates();
    setLoadWithFilter(false);
    setresetFilter(true);
  };

  return (
    <div style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}>
      <Row gutter={[12, 12]} justify={'center'} align={'middle'}>
        <Col>
          Filters:
        </Col>
        <Col span={4} md={4} sm={8} lg={4}>
          <Select
            placeholder='State'
            options={states}
            style={{ width: "100%" }}
            value={filter?.state}
            onChange={(value) => setFilter((d) => ({ ...d, state: value }))}
          />
        </Col>
        <Col span={4} md={4} sm={8} lg={4}>
          <Select
            placeholder='Insurance Package'
            options={packages}
            style={{ width: "100%" }}
            value={filter?.package}
            onChange={(value) => setFilter((d) => ({ ...d, package: value }))}
          />
        </Col>
        <Col span={4}>
          {/* <Select
            placeholder='Gender'
            options={gender}
            style={{ width: "100%" }}
            value={filter?.gender}
            onChange={(value) => setFilter((d) => ({ ...d, gender: value }))}
            // mode="tags"
          /> */}
          <DebounceSelect value={filter?.social} fetchOptions={loadOrgs} onChange={(value) => setFilter(d => ({...d, social: value}))} placeholder="Org Name/Social No" style={{width: '100%'}}/>
        </Col>
        <Col>
          {/* <Col span={8} md={8} sm={24} lg={8}> */}
          {/* <RangePicker
            picker="day"
            onChange={onChange}
            allowEmpty={[true, true]}
            style={{ width: "100%" }}
            value={[filterDate?.start, filterDate?.end]}
          /> */}
          <ConfigurableRangePicker ref={pickerRef} onChange={onChange} pickerType="day" />
        </Col>
        <Col>
          <Row gutter={8}>
            {!loadWithFilter ?
            <Col>
              <Button
                style={{ width: "100%", placeContent: "center" }}
                onClick={refreshSummary}
                disabled={loadWithFilter}
              >
                Use Filter
              </Button>
            </Col> : 
            <Col>
              <Button
                style={{ width: "100%" }}
                onClick={resetFilter}
                disabled={!loadWithFilter}
              >
                Clear Filter
              </Button>
            </Col>}
          </Row>
        </Col>

      </Row>
      <br></br>
      {/* {loadWithFilter && (
        <Row gutter={[12, 12]} style={{ textTransform: "uppercase" }}>
          <Col
            span={3}
            style={{
              fontSize: "28",
              fontWeight: "bolder",
              display: "flex",
              alignItems: "center",
            }}
          >
            Filters <DoubleRightOutlined />
          </Col>
          {filter.state && (
            <>
              <Col span={3} style={{ fontSize: "25", fontWeight: "bolder" }}>
                State:
              </Col>
              <Col
                span={4}
                style={{
                  fontSize: "20",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                }}
              >
                {filter.state}
              </Col>
            </>
          )}
          {filter.package && (
            <>
              <Col span={3} style={{ fontSize: "25", fontWeight: "bolder" }}>
                Package:
              </Col>
              <Col
                span={4}
                style={{
                  fontSize: "20",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                }}
              >
                {filter.package}
              </Col>
            </>
          )}
          {filterDate.start && filterDate.end && (
            <>
              <Col span={3} style={{ fontSize: "25", fontWeight: "bolder" }}>
                Date Range:
              </Col>
              <Col
                span={4}
                style={{
                  fontSize: "20",
                  fontWeight: "bolder",
                  fontStyle: "italic",
                }}
              >
                {filterDate.start.format("YYYY-MM-DD")} to {" "}
                {filterDate.end.format("YYYY-MM-DD")}
              </Col>
            </>
          )}
        </Row>
      )} */}
      {!loading ? (
        Object.keys(summary).length ? (
          <>
            {[1, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Finances - Revenue
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={6} sm={12} lg={6} style={{ cursor: "pointer" }}>
                    <Card
                      bordered={false}
                      onClick={showOrgDrawer}
                      hoverable
                      actions={[<>&nbsp;</>]}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Total Reveniue</strong>}
                        value={summary?.totalPolicyCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={6} sm={12} lg={6} style={{ cursor: "alias" }}>
                    <Card
                      bordered={false}
                      actions={[<>25% of the Total Revenue</>]}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Wakaala Fee</strong>}
                        value={parseFloat(summary?.wakaalaFee)}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={6} sm={12} lg={6} style={{ cursor: "alias" }}>
                    <Card
                      bordered={false}
                      actions={[<>1% of the Total Revenue</>]}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>NHIS Fee</strong>}
                        value={parseFloat(summary?.nhisFee)}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={6} sm={12} lg={6} style={{ cursor: "alias" }}>
                    <Card
                      bordered={false}
                      actions={[<>MLR</>]}
                    >
                      <Statistic
                        suffix="%"
                        title={<strong>Medical Loss Ratio</strong>}
                        value={parseFloat(summary?.medical_loss_ratio)}
                        precision={4}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
            {[1, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Finances - Expenditure
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showAllClaimDrawerCFO}>
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Total Claims</strong>}
                        value={summary?.totalClaimsCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showClaimsPaidDrawer}>
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Total Claims Paid</strong>}
                        value={summary?.totalClaimsPaidCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card
                      bordered={false}
                      onClick={showAClaimPendingPaymentDrawer}
                    >
                      <Statistic
                        prefix="&#8358;"
                        title={<strong>Pending Payment</strong>}
                        value={summary?.totalClaimsPendingCost}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showAllClaimDrawer}>
                      <Statistic
                        title={<strong>Filed Claims</strong>}
                        value={summary?.totalClaimsFiled}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showPaidClaimDrawer}>
                      <Statistic
                        title={<strong>Paid Claims</strong>}
                        value={summary?.totalPaidClaims}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showPendingClaimDrawer}>
                      <Statistic
                        title={<strong>Pending Claims</strong>}
                        value={summary?.totalPendingClaims}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  {/* <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={showCheckedClients}>
                      <Statistic
                        title={<strong>Current Checked-ins</strong>}
                        value={summary?.activeCheckinCount}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col> */}
                </Row>
              </>
            )}

            {/* {
                ([1,2,3,4,5].includes(Number(user?.role?.staffRoleId))) && (
                  <> */}
            <Divider
              style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
              orientationMargin={0}
              orientation="left"
            >
              {/* {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && ( */}
              <span>Encounters</span>
              {/* )}{" "} */}
            </Divider>
            <Row gutter={[12, 12]} justify={"stretch"}>
              <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                {/* <Card bordered={false}> */}
                <Card bordered={false} onClick={() => showCheckedClients(null)}>
                  <Statistic
                    title={<strong>Total</strong>}
                    value={summary?.totalEncounters}
                    formatter={formatter}
                  />
                </Card>
              </Col>
              {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
                <>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Active</strong>}
                        value={summary?.activeEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Manual</strong>}
                        value={summary?.manualEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Direct</strong>}
                        value={summary?.directEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "pointer" }}>
                    <Card bordered={false} onClick={() => showCheckedClients(1)}>
                      <Statistic
                        title={<strong>In-patient</strong>}
                        value={summary?.inPatientEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false} >
                      <Statistic
                        title={<strong>Out-patient</strong>}
                        value={summary?.outPatientEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false} >
                      <Statistic
                        title={<strong>Antenatal</strong>}
                        value={summary?.antenatalEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false} >
                      <Statistic
                        title={<strong>Dental</strong>}
                        value={summary?.dentalEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false} >
                      <Statistic
                        title={<strong>Optical</strong>}
                        value={summary?.opticalEncounters}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </>
              )}
            </Row>

            <Divider
              style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
              orientationMargin={0}
              orientation="left"
            >
              {/* {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && ( */}
              <span>Pre-authorizations</span>
              {/* )}{" "} */}
            </Divider>
            <Row gutter={[12, 12]} justify={"stretch"}>
              {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
                <>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Pending PAs</strong>}
                        value={summary?.pending_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Approved PAs</strong>}
                        value={summary?.approved_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Rejected PAs</strong>}
                        value={summary?.rejected_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4} style={{ cursor: "not-allowed" }}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Queried PAs</strong>}
                        value={summary?.queried_pas}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </>
              )}
            </Row>

            {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Statistics - Insurance
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages</strong>}
                        value={summary?.plans}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  {/* <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages Categories</strong>}
                        value={summary?.categories}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col> */}
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Insurance Benefits</strong>}
                        value={summary?.items}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Self Paid Clients</strong>}
                        value={summary?.selfPaidPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Corporate Clients</strong>}
                        value={summary?.corporatePolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Clients</strong>}
                        value={summary?.totalPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Enrolee</strong>}
                        value={summary?.enrolees}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Financiers</strong>}
                        value={summary?.organizations}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            {/* {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Statistics - Policies
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Self Paid Clients</strong>}
                        value={summary?.selfPaidPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Corporate Clients</strong>}
                        value={summary?.corporatePolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Clients</strong>}
                        value={summary?.totalPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Enrolee</strong>}
                        value={summary?.enrolees}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Financiers</strong>}
                        value={summary?.organizations}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )} */}

            {[1, 2, 3, 4, 5].includes(Number(user?.role?.staffRoleId)) && (
              <>
                <Divider
                  style={{ width: "100%", margin: "35px 0 5px 0", padding: 0 }}
                  orientationMargin={0}
                  orientation="left"
                >
                  Facilities
                </Divider>
                <Row gutter={[12, 12]} justify={"stretch"}>
                  {/* <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages</strong>}
                        value={summary?.plans}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Packages Categories</strong>}
                        value={summary?.categories}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Insurance Benefits</strong>}
                        value={summary?.items}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Self Paid Clients</strong>}
                        value={summary?.selfPaidPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Corporate Clients</strong>}
                        value={summary?.corporatePolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Clients</strong>}
                        value={summary?.totalPolicies}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Platform Enrolee</strong>}
                        value={summary?.enrolees}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col> */}
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>Total</strong>}
                        value={summary?.facilitiesCount}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>North Central</strong>}
                        value={summary?.facilities_in_north_central}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>North East</strong>}
                        value={summary?.facilities_in_north_east}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>North West</strong>}
                        value={summary?.facilities_in_north_west}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>South East</strong>}
                        value={summary?.facilities_in_south_east}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>South South</strong>}
                        value={summary?.facilities_in_south_south}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                  <Col md={8} sm={12} lg={4}>
                    <Card bordered={false}>
                      <Statistic
                        title={<strong>South West</strong>}
                        value={summary?.facilities_in_south_west}
                        precision={2}
                        formatter={formatter}
                      />
                    </Card>
                  </Col>
                </Row>
              </>
            )}
          </>
        ) : (
          <Empty
            description={"Summary is not available"}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )
      ) : (
        <Loader size={100} />
      )}
      {!loadWithFilter && (
        <Drawer
          title="Organizationwise Contribution"
          width={720}
          closable={false}
          onClose={showOrgDrawer}
          open={showOrg}
        >
          <Organizations extended={false} filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Facilitywise Distribution - Paid Claims"
          width={720}
          closable={false}
          onClose={showClaimsPaidDrawer}
          open={showClaimsPaid}
        >
          <ClaimsListHookPayment
            extended={false}
            section="paid"
            filter={filter}
          />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Facilitywise Distribution - All Claims"
          width={720}
          closable={false}
          onClose={showAllClaimDrawerCFO}
          open={showAllClaimsCFO}
        >
          <ClaimsListHookPayment
            extended={false}
            section="all"
            filter={filter}
          />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Pending Payment"
          width={720}
          closable={false}
          onClose={showAClaimPendingPaymentDrawer}
          open={showClaimsPendingPayment}
        >
          <ClaimsListHookPayment
            extended={false}
            section="pending"
            filter={filter}
          />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Declined Payment"
          width={720}
          closable={false}
          onClose={showDeclinedClaimDrawer}
          open={showDeclinedClaims}
        >
          <ClaimsListHook extended={false} section="declined" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Filed"
          width={720}
          closable={false}
          onClose={showAllClaimDrawer}
          open={showAllClaims}
        >
          <ClaimsListHook extended={false} section="all" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Pending QA Vetting"
          width={720}
          closable={false}
          onClose={showPendingClaimDrawer}
          open={showPendingClaims}
        >
          <ClaimsListHook extended={false} section="pending" filter={filter} />
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Claims - Paid"
          width={720}
          closable={false}
          onClose={showPaidClaimDrawer}
          open={showPaidClaims}
        >
          <ClaimsListHook extended={false} section="paid" filter={filter} />
        </Drawer>
      )}
      {(!loadWithFilter && showCheckind) && (
        <Drawer
          title="Encounters Analytics"
          width={720}
          closable={false}
          onClose={() => showCheckedClients(null)}
          open={showCheckind}
          destroyOnClose
        >
          {/* <CheckinListHook extended={false} filter={filter} /> */}
          {/* {console.log(checkTypeToShow)} */}
          {
            checkTypeToShow === 1 ?
              <CheckinListAnalyticsInPatioent /> :
              <CheckinListAnalytics packages={packages} filter={filter}/>
          }
          {/* <CheckinListAnalyticsInPatioent /> */}
        </Drawer>
      )}
      {!loadWithFilter && (
        <Drawer
          title="Activity Logs"
          width={720}
          closable={true}
          onClose={showLogsDrawer}
          open={showLogs}
          destroyOnClose
        >
          <Logging extended={false} filter={filter} />
        </Drawer>
      )}
    </div>
  );
};

const Dashboard = () => {
  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          <PlansSummary />
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
