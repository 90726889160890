// import React from "react";
// import { DatePicker } from "antd";
// import dayjs from "dayjs";

// const { RangePicker } = DatePicker;

// const ConfigurableRangePicker = ({
//   minYear = 2022,
//   maxYear = dayjs().year(),
//   pickerType = "month", // Can be "month" or "year"
//   outputFormat = "YYYY-MM-DD HH:mm:ss",
//   startOfUnit = "month", // Can be "month", "year" or customize it
//   onChange
// }) => {
//   const disabledDate = (current) => {
//     return current.year() < minYear || current.year() > maxYear;
//   };

//   const handleChange = (values) => {
//     if (values) {
//       const formattedValues = values.map((date) =>
//         date ? date.startOf(startOfUnit).format(outputFormat) : null
//       );
//       onChange && onChange(formattedValues);
//     }
//   };

//   return (
//     <RangePicker
//       picker={pickerType}
//       disabledDate={disabledDate}
//       onChange={handleChange}
//     />
//   );
// };

// export default ConfigurableRangePicker;

// import React, { useState, forwardRef, useImperativeHandle } from "react";
// import { DatePicker } from "antd";
// import dayjs from "dayjs";

// const { RangePicker } = DatePicker;

// const ConfigurableRangePicker = forwardRef(
//   (
//     {
//       minYear = 2022,
//       maxYear = dayjs().year(),
//       pickerType = "month",
//       outputFormat = "YYYY-MM-DD HH:mm:ss",
//       startOfUnit = "month",
//       onChange,
//     },
//     ref
//   ) => {
//     const [selectedDates, setSelectedDates] = useState([]);

//     useImperativeHandle(ref, () => ({
//       clearDates: () => {
//         setSelectedDates([]);
//         onChange && onChange([]);
//       },
//     }));

//     const disabledDate = (current) => {
//       return current.year() < minYear || current.year() > maxYear;
//     };

//     const handleChange = (values) => {
//       if (values) {
//         const formattedValues = values.map((date) =>
//           date ? date.startOf(startOfUnit).format(outputFormat) : null
//         );
//         setSelectedDates(values);
//         onChange && onChange(formattedValues);
//       } else {
//         setSelectedDates([]);
//         onChange && onChange([]);
//       }
//     };

//     return (
//       <RangePicker
//         picker={pickerType}
//         disabledDate={disabledDate}
//         allowClear
//         value={selectedDates}
//         onChange={handleChange}
//       />
//     );
//   }
// );

// export default ConfigurableRangePicker;


// import React, { useState, forwardRef, useImperativeHandle } from "react";
// import { DatePicker } from "antd";
// import dayjs from "dayjs";

// const ConfigurableDatePickers = forwardRef(
//   (
//     {
//       minYear = 2022,
//       maxYear = dayjs().year(),
//       pickerType = "month", // Can be "month" or "year"
//       outputFormat = "YYYY-MM-DD HH:mm:ss",
//       startOfUnit = "month", // "month" or "year"
//       onChange,
//     },
//     ref
//   ) => {
//     const [startDate, setStartDate] = useState(null);
//     const [endDate, setEndDate] = useState(null);

//     // Expose a method to clear both date pickers
//     useImperativeHandle(ref, () => ({
//       clearDates: () => {
//         setStartDate(null);
//         setEndDate(null);
//         onChange && onChange({ start: null, end: null });
//       },
//     }));

//     // Restrict selectable years
//     const disabledDate = (current) => {
//       return current.year() < minYear || current.year() > maxYear;
//     };

//     // Handle start date change
//     const handleStartChange = (value) => {
//       if (endDate && value && value.isAfter(endDate)) {
//         setEndDate(null); // Reset end date if start is after end
//       }
//       setStartDate(value);
//       onChange && onChange({ start: formatDate(value), end: formatDate(endDate) });
//     };

//     // Handle end date change
//     const handleEndChange = (value) => {
//       if (startDate && value && value.isBefore(startDate)) {
//         setStartDate(null); // Reset start date if end is before start
//       }
//       setEndDate(value);
//       onChange && onChange({ start: formatDate(startDate), end: formatDate(value) });
//     };

//     const formatDate = (date) => {
//       return date ? date.startOf(startOfUnit).format(outputFormat) : null;
//     };

//     return (
//       <div style={{ display: "flex", gap: "10px" }}>
//         <DatePicker
//           picker={pickerType}
//           disabledDate={(current) =>
//             disabledDate(current) || (endDate && current.isAfter(endDate))
//           }
//           allowClear
//           value={startDate}
//           onChange={handleStartChange}
//           placeholder="Start Date"
//         />
//         <DatePicker
//           picker={pickerType}
//           disabledDate={(current) =>
//             disabledDate(current) || (startDate && current.isBefore(startDate))
//           }
//           allowClear
//           value={endDate}
//           onChange={handleEndChange}
//           placeholder="End Date"
//         />
//       </div>
//     );
//   }
// );

// export default ConfigurableDatePickers;


import React, { useState, forwardRef, useImperativeHandle } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const ConfigurableDatePickers = forwardRef(
  (
    {
      minYear = 2022,
      maxYear = dayjs().year(),
      pickerType = "month", // Can be "day", "month", or "year"
      outputFormat = "YYYY-MM-DD", // Defaulting to just date, no time
      startOfUnit = "month", // "day", "month", or "year"
      onChange,
    },
    ref
  ) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    // Expose a method to clear both date pickers
    useImperativeHandle(ref, () => ({
      clearDates: () => {
        setStartDate(null);
        setEndDate(null);
        onChange && onChange({ start: null, end: null });
      },
    }));

    // Restrict selectable years
    const disabledDate = (current) => {
      return current.year() < minYear || current.year() > maxYear;
    };

    // Handle start date change
    const handleStartChange = (value) => {
      if (endDate && value && value.isAfter(endDate)) {
        setEndDate(null); // Reset end date if start is after end
      }
      setStartDate(value);
      onChange &&
        onChange({
          start: formatStartDate(value),
          end: formatEndDate(endDate),
        });
    };

    // Handle end date change
    const handleEndChange = (value) => {
      if (startDate && value && value.isBefore(startDate)) {
        setStartDate(null); // Reset start date if end is before start
      }
      setEndDate(value);
      onChange &&
        onChange({
          start: formatStartDate(startDate),
          end: formatEndDate(value),
        });
    };

    // Ensure correct date formatting
    const formatStartDate = (date) => {
      if (!date) return null;
      return pickerType === "day" ? date.format(outputFormat) : date.startOf(startOfUnit).format(outputFormat);
    };

    const formatEndDate = (date) => {
      if (!date) return null;
      return pickerType === "day" ? date.format(outputFormat) : date.endOf(startOfUnit).format(outputFormat);
    };

    return (
      <div style={{ display: "flex", gap: "10px" }}>
        <DatePicker
          picker={pickerType}
          disabledDate={(current) =>
            disabledDate(current) || (endDate && current.isAfter(endDate))
          }
          allowClear
          value={startDate}
          onChange={handleStartChange}
          placeholder="Start Date"
        />
        <DatePicker
          picker={pickerType}
          disabledDate={(current) =>
            disabledDate(current) || (startDate && current.isBefore(startDate))
          }
          allowClear
          value={endDate}
          onChange={handleEndChange}
          placeholder="End Date"
        />
      </div>
    );
  }
);

export default ConfigurableDatePickers;

