import { Select , Spin} from "antd";
import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";

export default function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
    const [fetching, setFetching] = useState(false);
    const [options, setOptions] = useState([]);
    const fetchRef = useRef(0);
  
    const debounceFetcher = useMemo(() => {
      const loadOptions = (value) => {
        if (value.length < 3) return;
        fetchRef.current += 1;
        const fetchId = fetchRef.current;
        setOptions([]);
        setFetching(true);
  
        fetchOptions(value).then((newOptions) => {
          if (fetchId !== fetchRef.current) {
            // Ignore fetches triggered before the current one
            return;
          }
          setOptions(newOptions);
          setFetching(false);
        });
      };
  
      return debounce(loadOptions, debounceTimeout);
    }, [fetchOptions, debounceTimeout]);
  
    return (
      <Select
        showSearch
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    );
  };
  