import React, { useState, useEffect, useRef } from "react";
import { useDispatch} from 'react-redux'
import { useNavigate } from "react-router-dom"
import { notification, Input,  Row, Col, Segmented, Empty, Avatar, Badge } from 'antd';
import { uri_img } from "../../assets/utils/http-request";
import { myRequestObj } from "../../assets/utils/lib";

import './preauth.css';
import { useAuth } from "../../assets/utils/useAuth";
import { selectclient } from '../../stores/verify';
import Helpers from "../../assets/utils/Helpers";

const PAListHook = () => {
  const navigate = useNavigate();
  const {set, user} = useAuth();
  const dispatcher = useDispatch(selectclient);
  const [claimList, setClaimsList] = useState([]);
  const [filterdClaimList, setFilterdClaimsList] = useState([]);
  const [activeSegment, setActiveSegment] = useState(0);
  const [filterKeyword, setFilterKeyword] = useState('');
  const [loadingData, setLoadingData] = useState(false);
  const segment = useRef(4);
  const itemStatusesKeys = ['Pending', 'Approved', 'Queried', 'Declined'];

  const itemStatuses =   {
    "Declined": {
        "count": 3,
        "color": "#ff0200",
        "text": "Declined"
    },
    "Queried": {
        "count": 0,
        "color": "#ff9200",
        "text": "Queried"
    },
    "Pending": {
        "count": 0,
        "color": "#121221",
        "text": "Pending"
    },
    "Approved": {
        "count": 5,
        "color": "#00aa00",
        "text": "Approved"
    },
    // "Manual": {
    //     "count": 6,
    //     "color": "#00aa00",
    //     "text": "Manual"
    // }
  }

  useEffect(() => {
    if(user){
      if(![1,2,4].includes(Number(user?.role['staffRoleId']))) {
        Helpers.logout(set, dispatcher);
        notification.info({
          message: 'Invalid User',
          description: 'Please login with the right permission', placement: 'topLeft'
        })
        navigate('/dashboard')
      }
    }else{  
      navigate('/login')
    }
  })

  useEffect(() => {
    setLoadingData(true);
    fetchClaimsList(user.id).then(res => {
      const dat = Object.values(res).map(c => {
        const this_dat = {};
        Object.entries(c).forEach(col => {
          this_dat[col[0]] = col[1] || '';
        })
        return this_dat;
      }).sort((a, b) => (new Date(b.date)) - (new Date(a.date)))
      setClaimsList(dat);
      setFilterdClaimsList(() => {
        return ( segment.current === 4 )? dat :
        dat.filter((val) => val.aggregatedItemsStatus.cat[itemStatusesKeys[segment.current]]['count'] > 0)
        .map(item => {
          item.aggregatedItemsStatus.code = segment.current; 
          item.aggregatedItemsStatus.text = itemStatuses[itemStatusesKeys[segment.current]]['text']
          item.aggregatedItemsStatus.color = itemStatuses[itemStatusesKeys[segment.current]]['color'] 
          return item
        })
      });
    }).catch(e => {})
    .finally(() => {
      update_pa_list();
    });
    setLoadingData(false);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    filterClaimsList(filterKeyword);
  }, [filterKeyword]) // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchClaimsList(claimsId) {
    return myRequestObj(`PAListAdmin`).post()
    .then((response) => response?.data)
    .then((body) =>{ 
      return body?.data;
    }).catch(e => {
      console.log('PA LIST Fetch Error', e.message)
    });
  }

  const handleSegmentChange = (value) => {
    segment.current = value;
    switch(value){
      default:
        break;
      case 0:
      case 1:
      case 2:
      case 3:
        setFilterdClaimsList(
          Object.values(claimList)
          .filter((val) => val.aggregatedItemsStatus.cat[itemStatusesKeys[value]]['count'] > 0)
          .map(item => {
            item.aggregatedItemsStatus.code = value; 
            item.aggregatedItemsStatus.text = itemStatuses[itemStatusesKeys[value]]['text']
            item.aggregatedItemsStatus.color = itemStatuses[itemStatusesKeys[value]]['color'] 
            return item
          })
        );
        break;
      case 4:
        setFilterdClaimsList(Object.values(claimList).sort((a, b) => (new Date(b.date)) - (new Date(a.date))));
        break;
      case 5:
        setFilterdClaimsList(Object.values(claimList).sort((a, b) => (new Date(b.date)) - (new Date(a.date)))); 
        break;
    }
    setActiveSegment(value);
    setFilterKeyword('')
  }

  const filterClaimsList = (e) => {
    const inputValue = e;
    if(inputValue){
      setFilterdClaimsList(claimList.filter((val) => {
        return (activeSegment)? 
          ((
            val.surname.toLowerCase().search(inputValue) >= 0 || val.first_name.toLowerCase().search(inputValue) >= 0 ||
            val.middle_name.toLowerCase().search(inputValue) >= 0 || val.insurance_no.toLowerCase().search(inputValue) >= 0 || val.code.toLowerCase().search(inputValue) >= 0 ||
            val.checkin_date.toLowerCase().search(inputValue) >= 0 || val.checkinType.toLowerCase().search(inputValue) >= 0 || val.policy_no.toLowerCase().search(inputValue) >= 0
            ) && (Number(val.aggregatedItemsStatus.code) === activeSegment - 1)
          ) : 
          (
            val.surname.toLowerCase().search(inputValue) >= 0 || val.first_name.toLowerCase().search(inputValue) >= 0 ||
            val.middle_name.toLowerCase().search(inputValue) >= 0 || val.insurance_no.toLowerCase().search(inputValue) >= 0 || val.code.toLowerCase().search(inputValue) >= 0 ||
            val.checkin_date.toLowerCase().search(inputValue) >= 0 || val.checkinType.toLowerCase().search(inputValue) >= 0 || val.policy_no.toLowerCase().search(inputValue) >= 0
          );
      }).sort((a, b) => (new Date(b.date)) - (new Date(a.date))))
    }else{
      (activeSegment)? 
        setFilterdClaimsList(claimList.filter((val) => Number(val.aggregatedItemsStatus.code) === activeSegment - 1).sort((a, b) => (new Date(a.date)) - (new Date(b.date)))):
        setFilterdClaimsList(claimList.sort((a, b) => (new Date(b.date)) - (new Date(a.date))));
    }
  }

  const update_pa_list = () => {
    setInterval(() => {
      if(loadingData) return;
      setLoadingData(false)
      if(window.location.pathname === '/preauth'){
        setLoadingData(true);
        fetchClaimsList(user.id).then(res => {
          const dat = Object.values(res).map(c => {
            const this_dat = {};
            Object.entries(c).forEach(col => {
              this_dat[col[0]] = col[1] || '';
            })
            return this_dat;
          }).sort((a, b) => (new Date(b.date)) - (new Date(a.date)))
          setClaimsList(dat);
          setFilterdClaimsList(() => {
            return ( segment.current === 4 )? dat :
            dat.filter((val) => val.aggregatedItemsStatus.cat[itemStatusesKeys[segment.current]]['count'] > 0)
            .map(item => {
              item.aggregatedItemsStatus.code = segment.current; 
              item.aggregatedItemsStatus.text = itemStatuses[itemStatusesKeys[segment.current]]['text']
              item.aggregatedItemsStatus.color = itemStatuses[itemStatusesKeys[segment.current]]['color'] 
              return item
            })
          });
        }).catch(e => {})
        .finally(
          setLoadingData(false)
        );
      }
      
    }, 3000)
  } 

  const style = { padding: '8px 0', justifyContent: 'center', textAlign: 'center', fontSize: '1.2em', fontWeight: 'bold'};

  return (
    <>
      <Segmented block 
        options={[
          {
            label: (
              <div style={{...style, color: '#0092ff', cursor: 'pointer'}}>All</div>
            ),
            value: 4
          },
          {
            label: (
              <div style={{...style, color: '#121221', cursor: 'pointer'}}>Pending</div>
            ),
            value: 0
          },
          {
            label: (
              <div style={{...style, color: '#00aa00', cursor: 'pointer'}}>Approved</div>
            ),
            value: 1
          },
          {
            label: (
              <div style={{...style, color: '#ff9200', cursor: 'pointer'}}>Queried</div>
            ),
            value: 2
          },
          {
            label: (
              <div style={{...style, color: '#ff0200', cursor: 'pointer'}}>Declined</div>
            ),
            value: 3
          },
          {
            label: (
              <div style={{...style, color: 'grey', cursor: 'pointer'}}>Manual</div>
            ),
            value: 5
          }
        ]} 
        onChange={handleSegmentChange}
        style={{userSelect: 'none'}}
      />
      <div style={{marginTop: 10}}>
        <Row justify="end" align="middle">
          <Col span={2}>
            <span style={{fontWeight: 'bolder', fontSize: '1.2em'}}>
              Search:
            </span>
          </Col>
          <Col span={8}>
            <Input placeholder="Type Filter Word" value={filterKeyword.__lower()} onChange={e => setFilterKeyword(e.target.value.__lower())}/>
          </Col>
        </Row>
        {
          (filterdClaimList.length)?
            filterdClaimList.map(claim => (
                <Row style={{padding: '5px 0', cursor: 'pointer'}} 
                  onClick={() => {
                    navigate(`/preauth-action?checkin=${claim.code}${(segment.current !== 4)? '&filter='+itemStatusesKeys[segment.current] : ''}`)
                  }}
                >
                  <Col span={24}>
                    <Badge.Ribbon 
                      text={
                        <>
                          <Badge count={claim.queries || null} offset={[10, -8]}>
                            <span style={{color: 'white'}}>
                              {claim.aggregatedItemsStatus.text}
                            </span>
                          </Badge>
                        </>
                      } color={claim.aggregatedItemsStatus.color} placement='end'>
                      <Row justify="space-between" style={{padding: '2px 10px', boxShadow: '2px 2px 5px rgba(0,0,0, .4)'}} align='middle'>
                        <Col span={1}>
                          <Avatar size={64} icon={<img src={`${uri_img}${claim.pic_name}`} alt={claim.first_name} />}/>
                        </Col>
                        <Col span={8}>
                          <h6 style={{margin: '2px 0px', padding: '2px 0px', textDecorationStyle: 'underline'}}>Encounter Data</h6>
                          <span>Full Name: {claim?.surname?.__capitalize()}, {claim.first_name && `${claim?.first_name?.__capitalize()}`} {claim?.middle_name?.__capitalize()}</span><br></br>
                          <span>Encounter Code: {claim.code}</span><br></br>
                          <span>Encounter Date: {(new Date(claim.checkin_date).toDateString())}</span><br></br>
                          <span>PA Requests: {claim?.pa_items_count} Items</span>
                        </Col>
                        <Col span={8}>
                          <h6 style={{margin: '2px 0px', padding: '2px 0px', textDecorationStyle: 'underline'}}>Facility Of Encounter</h6>
                            <span className="card-c-label">Facility Name: {claim?.facility_name?.__upper()}</span><br></br>
                            <span className="card-c-label"> Facility Email: {claim?.facility_email}</span><br></br>
                            <span className="card-c-label"> Facility Supported Plan: {claim?.facility_plan_type?.__capitalize()}</span><br></br>
                            <span className="card-c-label"> State / City: {claim?.facility_state?.__upper()}{" / "}{claim?.facility_city?.__upper()}</span>
                        </Col>
                        <Col span={4} >
                          <h6 style={{margin: '2px 0px', padding: '2px 0px', textDecorationStyle: 'underline'}}>Statuses</h6>
                          <Row justify="space=between">
                            <Col span={12} style={{color: `${claim.aggregatedItemsStatus.cat.Pending.color}`}}>{claim.aggregatedItemsStatus.cat.Pending.text}:</Col> 
                            <Col>{claim.aggregatedItemsStatus.cat.Pending.count}</Col>
                          </Row>
                          <Row justify="space=between">
                            <Col span={12}  style={{color: `${claim.aggregatedItemsStatus.cat.Queried.color}`}}>{claim.aggregatedItemsStatus.cat.Queried.text}:</Col>
                            <Col>{claim.aggregatedItemsStatus.cat.Queried.count}</Col>
                          </Row>
                          <Row justify="space=between">
                            <Col span={12}  style={{color: `${claim.aggregatedItemsStatus.cat.Approved.color}`}}>{claim.aggregatedItemsStatus.cat.Approved.text}:</Col>
                            <Col>{claim.aggregatedItemsStatus.cat.Approved.count}</Col>
                          </Row>
                          <Row justify="space=between">
                            <Col span={12}  style={{color: `${claim.aggregatedItemsStatus.cat.Declined.color}`}}>{claim.aggregatedItemsStatus.cat.Declined.text}:</Col>
                            <Col>{claim.aggregatedItemsStatus.cat.Declined.count}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Badge.Ribbon>
                  </Col>
                </Row>
              ))
          :
            <>
              <Empty 
                description={
                  <span>
                    There are no claims in this category
                  </span>
                }
                style={{padding: 50, width: '100%'}}
              >
              </Empty>
            </>
        }
      </div>
        
    </>
  )
}

const PAHook = () => {  
  return (
    <>
      <PAListHook />
    </>
  )
}

export default PAHook;