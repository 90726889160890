import { ArrowLeftOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Col, Empty, notification, Row, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from '../compoinents/loader';
import { myRequestObj } from '../../assets/utils/lib';
import { uri_img } from '../../assets/utils/http-request';

const FacilityClaims = ({ extended = true }) => {
    const [loading, setLoading] = useState();
    const interval = useRef();
    const { facility } = useParams();
    const { segment } = useParams();
    const [targetFacility, setTargetFacility] = useState({});
    const [targetFacilityData, setTargetFacilityData] = useState([]);
    const navigate = useNavigate();

    let entry, seg;

    const fetchOperation = useRef(false);

    const loadFacility = (entry) => {
        entry && (
            myRequestObj('loadFacility').post({ entry })
                .then(data => data?.data)
                .then(data => {
                    if (!data?.status && Number(data?.error) === 0) {
                        setTargetFacility(data?.data)
                    }
                    else {
                        notification.error({
                            message: "Unable to load facility data, you can continue fetting the claims or use the the refresh button to reload the data."
                        })
                    }
                })
        )
    }

    const handleClick = () => {
        try {
            entry = atob(facility);
            seg = atob(segment);
        } catch (e) {
            notification.error({
                message: 'Error while processing request'
            })
            return;
        }
        loadFacility(entry)

        clearInterval(interval.current);
        interval.current = null;
        fetchClaimsList(entry, seg, true)
        if (!interval.current) {
            interval.current = setInterval(() => {
                fetchClaimsList(entry, seg)
            }, 5000)
        }
    }

    useEffect(() => {
        handleClick();
        return clearInterval(interval.current);
    }, []) // eslint-disable-line

    async function fetchClaimsList(entry, segment, loader = false) {
        loader && setLoading(true)
        if(fetchOperation.current) return ;
        fetchOperation.current = true;
        myRequestObj(`aClaimsListByFacility`).post({ entry, segment })
            .then((response) => response?.data)
            .then((body) => {
                const data = body?.data
                setTargetFacilityData(data)
            }).finally(() => {
                loader && setLoading(false)
                fetchOperation.current = false;
            });
    }

    return (
        // your component code here
        <>
            {!loading && <div style={{ display: "flex", flexDirection: 'column', width: '100%', alignItems: 'center', gap: 1, justifyContent: 'center', fontSize: 25 }}>
                <strong>{targetFacility?.facility_name}, {targetFacility?.facility_city} {targetFacility?.facility_state}</strong>
                <a style={{ padding: 0, margin: 0 }} href={`mailto:${targetFacility?.facility_email}`}>{targetFacility?.facility_email}</a>
                <a style={{ padding: 0, margin: 0 }} href={`tel:${targetFacility?.facility_phone}`}>{targetFacility?.facility_phone}</a>
            </div>}
            {!loading && <Space style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button onClick={() => navigate('/claims')} style={{ marginTop: 20, display: 'flex', alignItems: 'center', borderRadius: 50 }} title="Back" icon={<ArrowLeftOutlined />} >Back</Button>
                <Button onClick={handleClick} style={{ marginTop: 20, display: 'flex', alignItems: 'center', borderRadius: 50 }} title="Reload" icon={<Loading3QuartersOutlined />} >Reloaded</Button>
            </Space>}
            <div style={{ padding: '20px 0' }}>
                {loading && <Loader />}
                {!loading && targetFacilityData?.map((clm, index) =>
                    <Row id={`stop-${entry}-${index}`}>
                        <Col span={24}>
                            {
                                (clm.aggregatedItemsStatus) ?
                                    <>
                                        <Badge.Ribbon
                                            text={
                                                <>
                                                    <Badge count={clm.queries || null} offset={[10, -8]}>
                                                        <span style={{ color: 'white' }}>
                                                            {clm.flag.text}
                                                        </span>
                                                    </Badge>
                                                </>
                                            }
                                            color={clm.flag.color} placement='end'
                                        >
                                            <Row justify="space-between" align="middle"
                                                style={{
                                                    padding: '2px 10px',
                                                    boxShadow: '2px 2px 5px rgba(0,0,0, .4)',
                                                    cursor: 'pointer',
                                                    width: '100%'
                                                }}
                                                onClick={() => navigate(`/claims/filings/?encounter=${clm.checkin_id}`, { state: { facility, segment } })}
                                            >
                                                <Col span={2}>
                                                    <Avatar size={64} icon={<img src={`${uri_img}${clm.pic_name}`} alt={clm.first_name} />} />
                                                </Col>
                                                {
                                                    extended && (
                                                        <Col span={4}>
                                                            <p>
                                                                {clm.claims_id}
                                                            </p>
                                                        </Col>
                                                    )
                                                }
                                                <Col span={4}>
                                                    <p>
                                                        {clm?.surname?.__capitalize()}, &nbsp;
                                                        {clm?.first_name?.__capitalize()} &nbsp;
                                                        {clm?.middle_name?.__capitalize()}
                                                    </p>
                                                </Col>
                                                {
                                                    extended && (<>
                                                    {console.log(clm.source)}
                                                        <Col span={1}>
                                                            <p>
                                                                {Number(clm?.source) === 1 ? "Manual" : "Direct"}
                                                            </p>
                                                        </Col>
                                                        <Col span={3}>
                                                            <p>
                                                                {(new Date(clm?.claims_date)?.toLocaleString())}
                                                            </p>
                                                        </Col>
                                                    </>
                                                    )
                                                }
                                                <Col span={1}>
                                                    <p>
                                                        {clm?.pa_items_count}
                                                    </p>
                                                </Col>
                                                <Col span={extended ? 4 : 8}>
                                                    <Row justify="space=between">
                                                        <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Pending?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Pending?.text}:</Col>
                                                        <Col>{clm?.aggregatedItemsStatus?.cat?.Pending?.count}</Col>
                                                    </Row>
                                                    <Row justify="space=between">
                                                        <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Queried?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Queried?.text}:</Col>
                                                        <Col>{clm?.aggregatedItemsStatus?.cat?.Queried?.count}</Col>
                                                    </Row>
                                                    <Row justify="space=between">
                                                        <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Approved?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Approved?.text}:</Col>
                                                        <Col>{clm?.aggregatedItemsStatus?.cat?.Approved?.count}</Col>
                                                    </Row>
                                                    <Row justify="space=between">
                                                        <Col span={12} style={{ color: `${clm?.aggregatedItemsStatus?.cat?.Declined?.color}` }}>{clm?.aggregatedItemsStatus?.cat?.Declined?.text}:</Col>
                                                        <Col>{clm?.aggregatedItemsStatus?.cat?.Declined?.count}</Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </Badge.Ribbon>
                                    </>
                                    : null
                            }
                        </Col>
                    </Row>
                )}
                {!loading && !targetFacilityData?.length ? <Empty /> : null}
            </div>
        </>
    )
}

export default FacilityClaims;