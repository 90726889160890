import axios from "axios";

export const isDev = () =>   !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const uri = isDev()? 'https://v1.claima.io' : 'https://v1.claima.io';
export const uri_img = 'https://v1.claima.io';

export const headers =  {
  'Content-Type': 'application/json',
  'Cache-Control': 'no-cache, no-store, must-revalidate',
  'Pragma': 'no-cache',
  'Expires': 0,
  'Version': '1.5.1',
  'Application': 2,
  'Dev':  isDev()? 1 : 0
}

  
const Axios = axios.create({baseURL: uri, headers });

export default Axios;